import * as React from "react"

function IconWhats(props) {
  return (
    <svg viewBox="0 0 59.12 59.51" {...props}>
      <path
        d="M43.24 34.83c-.68-.37-4-2.18-4.67-2.44s-1.09-.39-1.57.29-1.88 2.19-2.31 2.64-.83.49-1.51.12a19.17 19.17 0 01-5.46-3.66 20.83 20.83 0 01-3.68-5c-.39-.71 0-1.07.35-1.41s.73-.78 1.1-1.17a4.71 4.71 0 00.73-1.14 1.28 1.28 0 000-1.22c-.16-.35-1.42-3.82-1.95-5.24s-1.12-1.2-1.52-1.21-.87-.09-1.33-.11a2.56 2.56 0 00-1.89.8 7.82 7.82 0 00-2.65 5.7c-.13 3.42 2.24 6.82 2.57 7.3S24 37 30.92 40.06s7 2.12 8.27 2S43.37 40.57 44 39a5.84 5.84 0 00.53-3.29c-.16-.3-.62-.49-1.3-.86zM30.47 1.42A27.22 27.22 0 007.15 42.7L2.24 57.31l15.07-4.83A27.23 27.23 0 1030.47 1.42z"
        fill="none"
        strokeMiterlimit={10}
        strokeWidth="4px"
        data-name="Capa 2"
      />
    </svg>
  )
}

export default IconWhats
