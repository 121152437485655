import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-xhr-backend"
import { initReactI18next } from "react-i18next"
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "es",
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: false,
    },

    resources: {
      es: {
        translations: require("./locales/es/index.json"),
      },
      en: {
        translations: require("./locales/en/index.json"),
      },
    },
  })
export default i18n
