import * as React from "react"

function IconFb(props) {
  return (
    <svg viewBox="0 0 30.86 59.83" {...props}>
      <path
        d="M28.16 30.42h-8.74v28h-12v-28h-6v-11h6v-6.35c0-4.54 2.35-11.65 12.73-11.65h8.27v10H22c-1.07 0-2.55.53-2.55 2.79v5.21h9.87z"
        fill="none"
        // stroke="#dbc1b0"
        strokeMiterlimit={10}
        strokeWidth="4px"
        data-name="Capa 2"
      />
    </svg>
  )
}

export default IconFb
