const chatSteps = [
  {
    id: "1",
    message: "¡Hola! Soy el asistente virtual de Rumah Cumbres.",
    trigger: "2",
  },

  {
    id: "2",
    message: "¿En qué podemos ayudarte?",
    trigger: "questions",
  },
  {
    id: "questions",
    user: true,

    validator: value => {
      if (!value) {
        return "Por favor introduce un mensaje"
      }
      return true
    },
    trigger: "4",
  },

  {
    id: "4",
    message:
      "De acuerdo.  A continuación, voy a pedirte unos datos para ponerte en contacto con nuestro coach inmobiliario, quien te atenderá personalmente.",
    trigger: "5",
  },
  {
    id: "5",
    message: "¿En qué modelo estás interesado?",
    trigger: "models",
  },
  {
    id: "models",
    options: [
      { value: "departamento", label: "Departamento", trigger: "6" },
      { value: "penthouse", label: "Penthouse", trigger: "6" },
    ],
  },
  {
    id: "6",
    message: "De acuerdo, ¿Qué uso tienes pensado darle al inmueble?",
    trigger: "7",
  },
  {
    id: "7",
    options: [
      { value: "habitar", label: "Para habitar", trigger: "use" },
      { value: "invertir", label: "Para invertir", trigger: "use" },
    ],
  },
  {
    id: "use",
    message: "¿Cuál es tu nombre completo?",

    trigger: "8",
  },
  {
    id: "8",
    user: true,
    trigger: "9",
  },
  {
    id: "9",
    message: "¿Y tu número de celular?",
    trigger: "10",
  },
  {
    id: "10",
    user: true,
    trigger: "11",
  },
  {
    id: "11",
    message: "¿Cuál es tu email?",
    trigger: "12",
  },
  {
    id: "12",
    user: true,
    trigger: "13",
  },
  {
    id: "13",
    message: "¿Por qué medio prefieres que te contacte?",
    trigger: "medium",
  },
  {
    id: "medium",
    options: [
      { value: "email", label: "Email", trigger: "14" },
      { value: "llamada", label: "Llamada telefónica", trigger: "14" },
      { value: "whatsapp", label: "Whatsapp", trigger: "14" },
    ],
  },
  {
    id: "14",
    message:
      "De acuerdo, en breve nuestro coach te atenderá. Gracias por tu interés. Mientras tanto te invitamos a realizar el recorrido virtual de nuestro departamento muestra",
    trigger: "15",
  },
  {
    id: "15",
    component: (
      <div>
        <Link to="https://my.matterport.com/show/?m=VQjj3dcjmmS&back=1">
          TOUR VIRTUAL
        </Link>
      </div>
    ),
    end: true,
  },
]

import { Link } from "gatsby"
import React, { useState } from "react"
import ChatBot from "react-simple-chatbot"
import { ThemeProvider } from "styled-components"

interface Props {}
const ChatSteps = (props: Props) => {
  const theme = {
    background: "#FFF8EF",
    fontFamily: "SinkinSans",
    headerFontColor: "#004F51",
    headerFontSize: "15px",
    botBubbleColor: "#004F51",
    botFontColor: "#ccaa97",
    userBubbleColor: "#E3E3E3",
    userFontColor: "#727272",
  }
  const [response, setResponse] = useState({
    type: "",
    message: "",
  })

  const handleEnd = async ({ steps, values }) => {

    const contact = {
      name: values[3],
      email: values[5],
      subject: "Contacto Chatbot RUMAH",
      message: `${values[0]}, me intersa un ${values[1]} para ${values[2]}, mi teléfono de contacto es: ${values[4]}, me puede contactar por ${values[6]}`,
      replyTo:
        "Muchas gracias por ponerse en contacto con nosotros, en breve nos comunicaremos para responder todas sus dudas.",
      accessKey: "30d56755-8db6-444b-ac9d-8e8a507ae72d",
    }

    try {
      const res = await fetch("https://api.staticforms.xyz/submit", {
        method: "POST",
        body: JSON.stringify(contact),
        headers: { "Content-Type": "application/json" },
      })

      const json = await res.json()

      if (json.success) {
        setResponse({
          type: "success",
          message: "Thank you for reaching out to us.",
        })
      } else {
        setResponse({
          type: "error",
          message: json.message,
        })
      }
    } catch (e) {
      console.log("An error occurred", e)
      setResponse({
        type: "error",
        message: "An error occured while submitting the form",
      })
    }
  }
  const color = "#000000"
  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        handleEnd={handleEnd}
        headerTitle={"Asesor Virtual".toUpperCase()}
        placeholder={"escribe tu mensaje".toUpperCase()}
        hideBotAvatar
        hideUserAvatar
        floating
        floatingStyle={{
          background: "#004F51",
          fill: "#ccaa97",
        }}
        steps={chatSteps}
        inputStyle={{ background: "#fff", color: "#555" }}
        submitButtonStyle={{ fill: "#004F51" }}
        style={{ borderRadius: 0 }}
        bubbleStyle={{ boxShadow: `0 25px 50px -12px ${color}` }}
        floatingIcon={
          <svg viewBox="0 0 97 97" width="56px" height="56px" {...props}>
            <defs>
              <style>{".cls-2{isolation:isolate}"}</style>
            </defs>
            <g id="Capa_2" data-name="Capa 2">
              <g id="curvas">
                <path
                  id="Path_38-2"
                  data-name="Path 38-2"
                  d="M48.5 0A48.5 48.5 0 110 48.5 48.49 48.49 0 0148.5 0z"
                  fill="#084f51"
                />
                <g id="_" className="cls-2">
                  <path
                    d="M53.24 48.15l-1.33.52c-2.66 1-3 1.93-3 5v1.19h-4.2V53.4c0-4.13.84-6.61 5.84-8.51l.81-.27c2-.78 2.66-1.72 2.66-3.16 0-1.61-2-3.64-5.08-3.64s-5 1.12-7 3.68l-3.18-2.91A12.75 12.75 0 0148.9 34c5.88 0 9.38 3.92 9.38 7.49a7.27 7.27 0 01-5.04 6.66zM46.77 63c-1.89 0-2.84-1.26-2.84-3.12s1-3.08 2.84-3.08 2.83 1.26 2.83 3.08S48.59 63 46.77 63z"
                    fill="#d3b29d"
                    className=""
                  />
                </g>
                <g id="Ellipse_8" data-name="Ellipse 8" fill="none">
                  <circle cx={48.5} cy={48.5} r={32} />
                  <circle
                    cx={48.5}
                    cy={48.5}
                    r={31}
                    stroke="#d3b29d"
                    strokeWidth="2px"
                  />
                </g>
              </g>
            </g>
          </svg>
        }
      />
    </ThemeProvider>
  )
}

export default ChatSteps
