import { motion, useCycle } from "framer-motion"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import React, { useEffect, useRef, useState } from "react"
import IsScrolling from "react-is-scrolling"
import LogoRumah from "../images/logo.png"
import IconFb from "./IconFb"
import IconInsta from "./IconInsta"
import IconLin from "./IconLin"
import IconWhats from "./IconWhats"
export const isMobile = () =>
  /iphone|ipod|android|ie|blackberry|fennec/i.test(navigator.userAgent)

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    strokeLinecap="round"
    {...props}
  />
)
export const MenuToggle = ({ toggle }) => (
  <button className="icon-button" onClick={toggle}>
    <svg viewBox="0 0 23 23" className="icon">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
      />
    </svg>
  </button>
)

const useWindowSize = () => {
  const isClient = typeof window === "object"

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }
  const [windowSize, setWindowSize] = useState(getSize)
  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [isClient, getSize])
  return windowSize
}
const navItems = [
  {
    to: "#conceptos",
    text: "Concepto",
  },
  {
    to: "#amenidades",
    text: "amenidades",
  },
  {
    to: "#modalidades",
    text: "modalidades",
  },
  {
    to: "#acabados",
    text: "Acabados",
  },
  {
    to: "#contacto",
    text: "Contacto",
  },
]

const Header = ({ isScrolling, isScrollingDown, isScrollingUp }) => {
  const [change, setChange] = useState("normal")

  useEffect(() => {
    let scroll
    if (isScrollingDown || isScrollingUp) {
      if (window) {
        typeof window !== "undefined" && window.clearTimeout(scroll)
      }
      if (change !== "sticky") {
        setChange("sticky")
      }
    }
    if (!isScrolling) {
      if (window) {
        typeof window !== "undefined" && window.clearTimeout(scroll)
      }
      if (change === "sticky") {
        scroll = setTimeout(() => {
          setChange("normal")
        }, 4000)
      }
    }
  }, [isScrollingDown, isScrollingUp, isScrolling, change])

  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef(null)
  const size = useWindowSize()

  return (
    <header className={`header  ${change}`}>
      <div className="header--content">
        <motion.nav
          className=""
          initial={false}
          animate={isOpen ? "open" : "closed"}
          custom={size.height}
          ref={containerRef}
        >
          <MenuToggle className="icon-button" toggle={() => toggleOpen()} />
          <motion.div
            variants={{
              open: (height = 1000) => ({
                clipPath: `circle(${height * 3 + 200}px at 0px 0px)`,
                transition: {
                  type: "spring",
                  stiffness: 30,
                  restDelta: 1,
                  delayChildren: 1000,
                },
                height: "120vh",
                zIndex: -1,
              }),
              closed: {
                clipPath: "circle(0px at 0px 0px)",
                transition: {
                  delay: 0.5,
                  type: "spring",
                  stiffness: 400,
                  damping: 40,
                  delayChildren: 100,
                },
                height: "0vh",
              },
            }}
            className="background"
          />
          <motion.ul
            className="ul"
            // className={`${isOpen ? "menu-open" : "menu-close"}`}
            variants={{
              open: {
                transition: { staggerChildren: 0.07, delayChildren: 0.3 },
                height: "80vh",
                decelerate: 10,
              },
              closed: {
                transition: { staggerChildren: 0.05, staggerDirection: -1 },
                height: 0,
                top: "10vh",
                padding: 0,
                margin: 0,
              },
            }}
          >
            <motion.div
              variants={{
                open: {
                  transition: {
                    delay: 10000,
                    ease: "easeIn",
                  },
                },
                close: {},
              }}
              className={"header--links-mobile " + (isOpen ? "open" : "close")}
            >
              <div className="header--socials">
                <a
                  rel="noreferrer"
                  target="_blamnk"
                  href="https://www.facebook.com/rumahcumbres/"
                  className="header--socials__item"
                >
                  <IconFb className="icon" />
                </a>
                <a
                  rel="noreferrer"
                  href="https://www.instagram.com/rumahcumbres/"
                  target="_blank"
                  className="header--socials__item"
                >
                  <IconInsta className="icon" />
                </a>
                <a
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/rumah-cumbres"
                  target="_blank"
                  className="header--socials__item"
                >
                  <IconLin className="icon" />
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={
                    isMobile
                      ? "https://api.whatsapp.com/send?phone=+5214426682200"
                      : "https://web.whatsapp.com/send?phone=+5214426682200"
                  }
                  className="header--socials__item"
                >
                  <IconWhats className="icon" />
                </a>
                <div className="header--socials__lang"></div>
              </div>
              <div className="header--menu">
                {navItems.map(item => (
                  <motion.li
                    key={item.text}
                    variants={{
                      open: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          y: { stiffness: 1000, velocity: -100 },
                        },
                      },
                      closed: {
                        y: 50,
                        opacity: 0,
                        transition: {
                          y: { stiffness: 1000 },
                        },
                      },
                    }}
                    whileHover={{
                      scale: 1,
                    }}
                    whileTap={{ scale: 0.95 }}
                    className="line-botom"
                    onClick={() => toggleOpen()}
                  >
                    <AnchorLink
                      activeClassName="header--menu__item_active"
                      className="header--menu__item"
                      to={item.to}
                      title={item.text}
                    >
                      {item.text}
                    </AnchorLink>
                  </motion.li>
                ))}
              </div>
            </motion.div>
          </motion.ul>
        </motion.nav>
        <div className="header--logo">
          <AnchorLink to="/">
            <img src={LogoRumah} alt="Rumah" />
          </AnchorLink>
        </div>
        <div className="header--links">
          <div className="header--socials">
            <a
              rel="noreferrer"
              target="_blamnk"
              href="https://www.facebook.com/rumahcumbres/"
              className="header--socials__item"
            >
              <IconFb className="icon" />
            </a>
            <a
              rel="noreferrer"
              href="https://www.instagram.com/rumahcumbres/"
              target="_blank"
              className="header--socials__item"
            >
              <IconInsta className="icon" />
            </a>
            <a
              rel="noreferrer"
              href="https://www.linkedin.com/company/rumah-cumbres"
              target="_blank"
              className="header--socials__item"
            >
              <IconLin className="icon" />
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href={
                isMobile
                  ? "https://api.whatsapp.com/send?phone=+5214426682200"
                  : "https://web.whatsapp.com/send?phone=+5214426682200"
              }
              className="header--socials__item"
            >
              <IconWhats className="icon" />
            </a>
            <div className="header--socials__lang"></div>
          </div>
          <div className="header--menu">
            <AnchorLink
              activeClassName="header--menu__item_active"
              className="header--menu__item"
              to="/#conceptos"
            >
              Concepto
            </AnchorLink>
            <AnchorLink
              activeClassName="header--menu__item_active"
              className="header--menu__item"
              to="/#amenidades"
            >
              amenidades
            </AnchorLink>
            <AnchorLink
              activeClassName="header--menu__item_active"
              className="header--menu__item"
              to="/#modalidades"
            >
              modalidades
            </AnchorLink>
            <AnchorLink
              activeClassName="header--menu__item_active"
              className="header--menu__item"
              to="/#acabados"
            >
              Acabados
            </AnchorLink>
            <AnchorLink
              activeClassName="header--menu__item_active"
              className="header--menu__item"
              to="/#contacto"
            >
              Contacto
            </AnchorLink>
          </div>
        </div>
      </div>
    </header>
  )
}

export default IsScrolling(Header)
export { Header as SimpleHeader }
