/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import PropTypes from "prop-types"
import React from "react"
import Headroom from "react-headroom"
import "../i18n"
import "../index.scss"
import ChatSteps from "./ChatSteps"
import Header, { SimpleHeader } from "./header"

const Layout = ({ children, isScrolling, isScrollingDown, isScrollingUp }) => {
  return (
    <>
      <div className="header-desktop">
        {typeof window !== `undefined` ? <Header /> : null}
      </div>

      <Headroom disableInlineStyles>
        <SimpleHeader />
      </Headroom>
      <div className="content">
        <main>{children}</main>
      </div>
      <ChatSteps />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
