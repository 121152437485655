import * as React from "react"

function IconInsta(props) {
  return (
    <svg viewBox="0 0 54.26 54.26"  {...props}>
      <path d="M41.2 52.84H13.06A11.65 11.65 0 011.42 41.2V13.06A11.65 11.65 0 0113.06 1.42H41.2a11.66 11.66 0 0111.65 11.64V41.2A11.66 11.66 0 0141.2 52.84zm-.27-42.12a3.38 3.38 0 103.38 3.38 3.37 3.37 0 00-3.38-3.38z" />
      <circle className="" cx={27.13} cy={27.13} r={11.55} />
    </svg>
  )
}

export default IconInsta
