import * as React from "react"

function IconLin(props) {
  return (
    <svg viewBox="0 0 56.54 54.16" {...props}>
      <path
        d="M7.93 1.42C4 1.42 1.42 4 1.42 7.4s2.5 6 6.36 6h.08c4 0 6.51-2.66 6.51-6s-2.49-5.98-6.44-5.98zM2.1 18.12v34.63h11.51V18.12zm30.32 3.63a11.15 11.15 0 019.46-4.44c7.58 0 13.25 4.94 13.25 15.58v19.86H43.62V34.22c0-4.65-1.67-7.83-5.83-7.83a6.31 6.31 0 00-5.91 4.21 8 8 0 00-.38 2.81v19.34H20s.16-31.39 0-34.63h11.5v3.63z"
        fill="none"
        strokeMiterlimit={10}
        strokeWidth="4px"
        data-name="Capa 2"
      />
    </svg>
  )
}

export default IconLin
